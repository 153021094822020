@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.container {
    position: relative;
    padding: 4% 8%;
    
    display: grid;
    grid-template-areas: 
        'info image'
        'achievements image';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, max-content);

    height: 100%;
    overflow: hidden;
}

.information {
    // height: 580px;
    grid-area: info;
    // max-height: 580px;
    // height: 100%;

    align-self: end;
    // margin-bottom: 52px;
    padding-bottom: 52px;
}

.title {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 32px;
    max-width: 15ch;

    strong {
        color: $color-primary;
        font-weight: 500;
    }
}

.description {
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    max-width: 40ch;

    margin-bottom: 0;

    @include medium-breakpoint {
        font-size: 18px;
    }

    @include small-breakpoint {
        font-size: 16px;
    }

    @include xsmall-breakpoint {
        font-size: 14px;
    }
}

.achievements {
    grid-area: achievements;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: max-content max-content;
    gap: 30px;
}

.image-container {
    grid-area: image;
    width: 60%;
    height: 650px;
    // height: 100%;
    display: flex;
    justify-content: right;
    align-items: flex-start;
    pointer-events: none;
}

.image {
    width: 100%;
    pointer-events: none;

    aspect-ratio: 1.08;

    & img {
        width: 100% !important;
        height: 100% !important;
        top: -8% !important;
        right: -8% !important;
        left: 8% !important;
        user-select: none;
        pointer-events: none;
    }
}

@include medium-breakpoint {
    .container {
        min-height: unset;
    }

    .information {
        padding-bottom: 32px;
    }

    .achievements {
        row-gap: 15px;
    }

    .title {
        font-size: 56px;
        margin-bottom: 28px;
    }

    .image-container {
        height: 550px;
    }

    .image {
        aspect-ratio: unset;
    }
}

@include small-breakpoint {
    .title {
        font-size: 40px;
        margin-bottom: 22px;
    }

    .image-container {
        height: 460px;
        width: 50%;
    }
}

@include media-tablet {
    .container {
        padding: 4% 16px;

        grid-template-areas: 
            'info image'
            'achievements achievements';
    }

    .information {
        align-self: center;
    }

    .achievements {
        grid-template-columns: repeat(4, max-content);
        grid-template-rows: max-content;

        justify-content: space-around;
    }

    .image-container {
        height: unset;
    }

    .image {
        & img {
            width: 100% !important;
            height: 100% !important;
            top: -10% !important;
            right: -15% !important;
            left: 15% !important;
        }
    }
}


@include xsmall-breakpoint {
    .title {
        font-size: 28px;
        margin-bottom: 16px;
    }

    .achievements {
        gap: 10px;
    }
}


@include media-tablet-portrait {
    .achievements {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, max-content);
    }

    .image {
        & img {
            right: -10% !important;
            left: 10% !important;
            top: -15% !important;
            bottom: 15% !important;
        }
    }
}

@include media-mobile {
    .container {
        display: flex;
        flex-direction: column;
        min-height: auto;
        max-height: unset;
    }

    .title {
        font-size: 36px;
    }

    .information {
        width: 100%;
    }

    .achievements {
        grid-template-columns: repeat(2, max-content);
        gap: 7px 20px;
        justify-content: start;
    }

    .reward {
        max-width: 200px;
    }

    .image-container {
        width: calc(100% + 16px * 2);
        margin: 28px -16px 0;
    }

    .image {
        position: relative;
        aspect-ratio: 1.08;

        img {
            width: 175% !important;
            height: 175% !important;
            top: -23% !important;
            right: 75% !important;
            left: -75% !important;
        }
    }
}

@media screen and (max-width: 350px) {
    .achievements {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, max-content);
    }
}