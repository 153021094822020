@import '~@ui/styles/variables';
@import '~@ui/styles/tools';

.achievements {
    display: grid;
    grid-template-columns: repeat(object-fit, 1fr);

    gap: 15px 30px;

    &--skeleton {
        display: grid;
        grid-template-columns: 100px 200px;
        gap: 15px 30px;

        @include media-mobile {
            gap: 10px;
        }
    }
}

.achievement {
    &:nth-of-type(1) {
        grid-row: 1 / 1;
        grid-column: 2 / 3;
    }

    &--skeleton {
        width: 100%;
        height: 55px;

        @include media-mobile {
            height: 30px;
        }
    }
}

@include media-mobile {
    .achievements {
        gap: 7px 20px;
    }
}

@media screen and (max-width: 335px) {
    .achievements {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, max-content);
    }

    .achievement {
        &:nth-child(n) {
            grid-column: 1 / 2;
        }

        &:nth-child(1) {
            grid-row: 3 / 4;
        }

        &:nth-child(2) {
            grid-row: 1 / 2;
        }

        &:nth-child(3) {
            grid-row: 2 / 3;
            max-width: 25ch;
        }

        &:nth-child(4) {
            grid-row: 4 / 5;
        }
    }
}
