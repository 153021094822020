@import '~@ui/styles/variables';
@import '~@ui/styles/tools';

@mixin medium-breakpoint-up {
    @media screen and (min-width: 1651px) {
        @content;
    }
}

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 650px;
    height: 100%;

    // Used for .mobile-button-wrapper with absolute positioning
    position: relative;
}

.desktop-view {
    .container {
        display: grid;
        grid-template-areas: 
            'info image'
            'achievements image';
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, max-content);
    }

    .main-info {
        grid-area: info;
        width: 100%;
    }

    .image-wrapper {
        grid-area: image;
        width: 100%;
    }

    .achievements {
        grid-area: achievements;
    }
}

.title {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 32px;
    max-width: 15ch;

    b,
    em,
    strong {
        font-weight: 500;
        color: $color-primary;
    }
}

.main-info__subsection {
    max-width: 90%;
}

.main-info__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 32px;
    max-width: 36ch;
}

.main-info__button-wrapper {
    margin-bottom: 52px;
    display: flex;
    justify-content: center;

    max-width: 243px;

    span {
        margin: 0 !important;
        padding: 0;
    }
}

.form-button {
    width: 243px;
    height: 68px;
    font-size: 20px;
    font-weight: 500;
}

.achievements {
    // display: flex;
    gap: 30px;
    // padding: 0;

                display: grid;
            grid-template-columns: max-content max-content;
            grid-template-rows: repeat(2, 1fr);
}

.image-wrapper {
    // Must use 'relative' positioning with 'fill' prop of next 'Image' component
    width: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    aspect-ratio: 1.84;

    // // Override NextJS img styles to keep correct aspect ratio for image
    img {
        display: grid;
        height: unset !important;
        aspect-ratio: 1.84;
        width: 140% !important;
        transform: translateX(-15%) translateY(-10%);
    }
}

.image-wrapper--mobile {
    // Must use 'relative' positioning with 'fill' prop of next 'Image' component
    position: relative;
    aspect-ratio: 1.84;
    width: 115%;
    margin-bottom: 20px;

    img {
        transform: translateY(-15%);
    }
}

.image {
    pointer-events: none;
}

.full-width {
    width: 100%;
}

@include medium-breakpoint-up {
    .container {
        height: 650px;
    }
}

@include medium-breakpoint {
    .desktop-view {
        .container {
            height: 550px;
        }

        .image-wrapper {
            img {
                transform: translateX(-15%) translateY(-5%);
            }
        }

        .achievements {
            row-gap: 15px;
        }
    }

    .container {
        padding-bottom: 0;
    }

    .title {
        font-size: 56px;
    }

    .main-info__description {
        font-size: 18px;
    }

    .main-info__button-wrapper {
        margin-bottom: 32px;
    }

    .achievements {
        padding-bottom: 0;
    }
}

@include small-breakpoint {
    .desktop-view {
        .container {
            height: 460px;
            align-items: center;
        }
    }

    .main-info:not(.main-info--mobile) {
        padding-top: initial;
    }

    .title {
        font-size: 40px;
    }

    .main-info__description {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .form-button {
        height: unset;
    }

    .achievements {
        padding: 0;
    }
}

@include media-tablet {
    .desktop-view {
        .container {
            height: unset;
            grid-template-areas: 
                'info image'
                'achievements achievements';
        }

        .image-wrapper {
            img {
                transform: translateX(-5%) translateY(-5%);
                width: 120% !important;
            }
        }

        .achievements {
            grid-template-columns: repeat(4, max-content);
            grid-template-rows: max-content;
            justify-content: space-around;
        }
    }

    .container {
        max-height: unset;
    }

    .title {
        margin-bottom: 20px;
    }
}


@include xsmall-breakpoint {
    .title {
        font-size: 28px;
        margin-bottom: 16px;
    }

    .main-info__description {
        font-size: 14px;
        margin-bottom: 16px;
    }

    .achievements {
        gap: 10px;
    }
}

@include media-tablet-portrait {
    .desktop-view {
        .achievements {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, max-content);
        }
    }
}

@include media-mobile {
    .desktop-view {
        display: none;
    }

    .container {
        flex-direction: column;
    }

    .main-info {
        padding: 0 16px;
        margin-bottom: 32px;
        width: 100%;
    }

    .title {
        margin-bottom: 24px;
    }

    .main-info__subsection {
        max-width: 100%;
    }

    .main-info__description {
        margin-bottom: 20px;
    }

    .main-info__button-wrapper {
        margin: 0 auto 32px;
        max-width: 250px;
    }

    .form-button {
        width: 250px;
        font-size: 16px;
        height: 55px;
    }

    .achievements {
        grid-template-columns: repeat(2, max-content);
        gap: 7px 20px;
        margin-bottom: 30px;
    }

    .image-wrapper {
        width: 100%;
    }

    .image-wrapper--mobile {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .achievements {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, max-content);
    }
}


@include media-mobile-up {
    .mobile-view {
        display: none;
    }
}

