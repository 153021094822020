@import "~@ui/styles/variables";
@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
        }
}

.form__achievement {
    display: flex;
    align-items: center;
    gap: 12px;

    cursor: pointer;

    &--wide {
        p {
            width: 205px;
            max-width: 25ch;

            @include medium-breakpoint {
                width: 180px;
                min-width: 175px;
                margin-right: -10px;
            }

            @include small-breakpoint {
                margin-right: -35px;
                width: 210px;
            }

            @include media-tablet {
                width: 205px;
                min-width: unset;
                margin-right: unset;
            }

            @include xsmall-breakpoint {
                width: 180px;
            }

            @include media-tablet-portrait {
                width: auto;
            }
        }
    }

    &:hover {
        text-decoration: none;
    }

    &:not(:disabled):hover {
        text-decoration: none;
    }

    &:visited:not(:disabled):hover {
        text-decoration: none;
    }

    &:active:not(:disabled) {
        text-decoration: none;
    }

    &:visited:not(:disabled):active {
        text-decoration: none;
    }

    @include medium-breakpoint {
        gap: 8px;
        font-size: 14px;
        line-height: 20px;
    }

    p {
        color: #030505;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 0;

        @include medium-breakpoint {
            font-size: 14px;
        }

        @include media-tablet {
            font-size: 16px;
        }

        @include xsmall-breakpoint {
            font-size: 14px;
        }

        @include media-tablet-portrait {
            font-size: 12px;
            line-height: 1.1;
        }
    }
}

.form__achievement--concise {
    max-width: 200px;
}

.form__achievement-icon-wrapper {
    display: inline-block;
    position: relative;
    min-width: 40px;
    min-height: 40px;

    @include small-breakpoint {
        min-width: 27px;
        min-height: 27px;
    }

//   @include media-tablet {
//     min-width: 40px;
//     min-height: 40px;
//   }

    @include media-tablet-portrait {
        min-width: 24px;
        min-height: 24px;
    }

    &--wide  {
        min-width: 80px;


        @include small-breakpoint {
            min-width: 54px;
        }

        @include media-mobile {
            min-width: 48px;
        }   
    }

    &--skolkovo {
        min-width: 135px;
        @include media-mobile {
            min-width: unset;
            width: 82px;
        }   
    }
}

.skolkovo-icon {
        @include media-mobile {
        height: 24px;
        width: 82px;
    }   
}